<template>
  <b-card>
    <b-row>
      <b-col md="4" class="pb-1">
        <b-button
          variant="outline-primary"
          :to="{ name: 'organization-worker-add' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          {{ $t('message.Add_Worker') }}
        </b-button>
      </b-col>

      <b-col md="4"  class="pb-1">
        <b-button
          v-if="role === 'consultant'"
          variant="outline-info"
          @click="isSupervisorsAssignationSidebarActive = true"
        >
          <feather-icon
            icon="Edit2Icon"
            class="mr-50"
          />
          {{ $t('assignSupervisors') }}
        </b-button>
      </b-col>

      <b-col md="4">
        <b-form-group>
          <b-form-input
            v-model="searchQuery"
            :placeholder="$t('message.search')"
            type="search"
            class="d-inline-block"
          />
        </b-form-group>
      </b-col>

      <b-col>
        <filters
          :locationFilterShow="true"
          :roleFilterShow="true"
          @changeFilter="updateFilter"
        />
      </b-col>

      <b-col cols="12">
        <b-table
          ref="refWorkerListTable"
          :items="workers"
          hover
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty=""
          :empty-text="isLoading ? $t('message.loading') : $t('message.no_records')"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(roles)="data">
            <b-badge
              v-for="(role, index) in data.item.roles.slice(0, 2)"
              :key="index"
              class="ml-1 mb-1"
              variant="light-primary"
            >
              {{ truncateWords(role.title) }}
            </b-badge>

            <feather-icon
              v-if="data.item.roles.length > 2"
              badge-classes="custom_badge_settings"
              :badge="`+${data.item.roles.length - 2}`"
              icon=""
              size="1"
            />
          </template>

          <template #cell(privileges)="data">
            <b-badge
              v-for="(role, index) in data.item.privileges.slice(0, 2)"
              :key="index"
              class="ml-1 mb-1"
              variant="light-danger"
            >
              {{ truncateWords(role.title) }}
            </b-badge>

            <feather-icon
              v-if="data.item.privileges.length > 2"
              badge-classes="custom_badge_settings"
              :badge="`+${data.item.privileges.length - 2}`"
              icon=""
              size="1"
            />
          </template>

          <template #cell(actions)="data">
            <div class="flex flex-column">
              <UserButton @clicked="router.push({name: 'apps-users-view', params: { id: data.item._id }})"/>
              <EditButton @clicked="router.push({name: 'organization-worker-edit', params: { id: data.item._id }})"/>
              <DeleteButton v-b-modal="`modal ${data.item._id}`"/>
              <b-modal
                :id="`modal ${data.item._id}`"
                centered
                no-close-on-backdrop
                modal-class="modal-danger"
                ok-variant="danger"
                cancel-variant="outline-secondary"
                :title="$t('message.confirm_action')"
                :ok-title="$t('message.delete')"
                :cancel-title="$t('message.cancel')"
                @ok="deleteWorker(data.item._id, deletedSuccess)"
              >
                {{ $t('message.confirm_delete_worker') }}
              </b-modal>
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
        class="d-flex justify-content-between flex-wrap"
      >
        <div class="mb-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t('message.per_page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="[5, 10, 15, 20, 25, 30, 40, 50, 100]"
              class="w-50"
            />
          </b-form-group>
        </div>

        <div class="mb-1">
          <span class="text-muted">{{ $tc('message.paginationText', 0, { from: `${((currentPage * perPage) - perPage) + (workers.length ? 1 : 0)}`, to: `${workers.length + ((currentPage * perPage) - perPage)}`, total: `${totalWorkers}` }) }}</span>
        </div>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalWorkers"
          :per-page="perPage"
          first-number=""
          last-number=""
          prev-class="prev-item"
          next-class="next-item"
        />
      </b-col>
    </b-row>

    <b-button :disabled="!workersTotal.length">
      <json-excel :data="workersTotal" :fields="excelFields">{{ $t('download_xlsx') }}</json-excel>
    </b-button>

    <!-- Sidebar Overlay: Assign Supervisors -->
    <div
      class="body-content-overlay"
      :class="{'show': isCalendarOverlaySidebarActive}"
      @click="isCalendarOverlaySidebarActive = false"
    />
    <supervisors-assignation
      v-model="isSupervisorsAssignationSidebarActive"
    />
  </b-card>
</template>

<script>
import { BBadge, BPagination, BTable, BModal, VBModal } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, onMounted, ref } from '@vue/composition-api'
import store from '@/store'
import JsonExcel from "vue-json-excel";
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import UserButton from '@/views/components/Shared/Buttons/UserButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'

import useNotifications from '@/composables/useNotifications'
import useWorkersList from './useWorkerList'

import workerStoreModule from '../workerStoreModule'
import i18n from '@/libs/i18n/index.js'
import Filters from "@/views/organization/Filters.vue";
import SupervisorsAssignation from '../supervisors-assignation/SupervisorsAssignation.vue'

export default {
  components: {
    BTable,
    BBadge,
    BPagination,
    EditButton,
    UserButton,
    DeleteButton,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    Filters,
    BModal,
    SupervisorsAssignation,
    JsonExcel,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      items: [],
    }
  },
  setup() {
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const ORGANIZATION_WORKER_STORE_MODULE_NAME = 'organization-worker'
    const { router } = useRouter()
    const {
      fetchWorkers,
      tableColumns,
      perPage,
      currentPage,
      totalWorkers,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      deleteWorker,
      refWorkerListTable,
      workers,
      locationQuery,
      locations,
      // getLocations,
      locationFilter,
      roleFilter,
      isLoading,
      role,
      excelFields,
      workersTotal
    } = useWorkersList()
    const isSupervisorsAssignationSidebarActive = ref(false)
    const isCalendarOverlaySidebarActive = ref(false)

    // Register module
    if (!store.hasModule(ORGANIZATION_WORKER_STORE_MODULE_NAME)) {
      store.registerModule(
        ORGANIZATION_WORKER_STORE_MODULE_NAME,
        workerStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORGANIZATION_WORKER_STORE_MODULE_NAME)) { store.unregisterModule(ORGANIZATION_WORKER_STORE_MODULE_NAME) }
    })

    function updateFilter(data) {
      locationFilter.value = data.locationFilter;
      roleFilter.value = data.roleFilter;

      fetchWorkers();
    }

    onMounted(async () => {
      fetchWorkers()
      // getLocations()
    })

    const deletedSuccess = success => {
      if (success) {
        fetchWorkers()
        showSuccessMessage(i18n.t('message.worker_remove'))
      } else {
        showErrorMessage(i18n.t('message.something_went_wrong'))
      }
    }

    return {
      router,
      fetchWorkers,
      tableColumns,
      perPage,
      currentPage,
      totalWorkers,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      deleteWorker,
      deletedSuccess,
      refWorkerListTable,
      workers,
      locationQuery,
      locitems: locations,
      // getLocations,
      updateFilter,
      isLoading,
      isSupervisorsAssignationSidebarActive,
      isCalendarOverlaySidebarActive,
      role,
      excelFields,
      workersTotal
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.process-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.form-group {
  label {
    font-size: 0.85rem;
    font-weight: 400;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
